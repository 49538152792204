<template>
  <div id="blind-box" class="view-blind-box" @scroll="handleScroll">
     <CompHeader title="盲盒开箱">
      <div class="comp-header-right-box">
        <div class="coin"></div>
      </div>
    </CompHeader>
    <div class="box-classify">
      <div class="blind-List-box">
         <div class="list-tile-box"  v-for="(item, index) in HotList" :key="index + 'a'" :class="{'list-tile-box-active':tabIndex === index}"  @click="tabChange(index)">
          <!-- 标题分类  -->
            <div v-if="item.box.length" class="classify-title">
                  {{item.name}}
            </div>
        </div>
      </div>
    </div>
    <div>
    <div class="play-introduce" @click="playPopupShow = !playPopupShow">
      <img src="../../assets/newImages/introduce-icon.png" alt="">
          玩法介绍
      </div>
    </div>
    <div class="home-box">
      <div class="home-List-box">
        <div v-for="(item, index) in HotList" :key="index + 'aa'">
          <div v-if="item.box.length && index == tabIndex" class="home-List">
            <div class="home-list-item" v-for="(item, index) in item.box" :key="index" @click="ToOpen(item.id)">
              <div class="left-icon">
              </div>
              <div class="wrap_pic">
                <img v-lazy="item.cover" />
                <div class="weapon_cover">
                  <img :src="item.weapon_cover" alt="" />
                </div>
              </div>
              <div class="Blind_btn">
                <span>{{ item.name }}</span>
              </div>
              <div class="box-price">
                <Hprice :price="item.bean" />
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 玩法说明弹窗 -->
      <van-overlay :lock-scroll="true" :show="playPopupShow">
        <playPopup v-if="playPopupShow" @close="playPopupShow = false" />
      </van-overlay>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import { GetHotBox, Info } from "@/network/api.js";
import { playingList } from "./index.js";
import MeanHeader from "@/components/common/MeanHeader.vue";
import Rank from "@/views/Rank/index.vue";
import playPopup from "@/components/common/PlayPopup.vue";
export default {
  components: {
    MeanHeader,
    Rank,
    playPopup,
  },
  name: "BlindBox",
  data() {
    return {
      HotList: [],
      BladeList: [],
      recruitList: [],
      classicList: [],
      vitalityList: [],
      hotboxlist: [],
      time: 10000,
      Headlines: [],
      soulList: [],
      XyList: [],
      swiperIndex: 0,
      swiperList: [],
      playingList: playingList,
      playingActive: null,
      // 侧边栏
      isScrolling: false,
      scrollTimer: null,
      sidebarTransform: '',
      playPopupShow: false,
      tabIndex:0

    };
  },

  created() {
    this.tabIndex = parseInt(localStorage.getItem('tabIndex') );
    this.getBannerList();
    this.ObtainHotBox();
  },

  filters: {
    GuoLvOne(val) {
    if (val.length > 14) {
        return val.substr(0, 14) + "...";
      } else {
        return val;
      }
    },
    StyChoice(val) {
    if (val == "1") {
        // return 'border-bottom-color:#e6ba1c;background-image: linear-gradient(to top, rgba(230, 186, 28,.5), transparent)'
        return (
          "background: url(" +
          require("@/assets/images/Roll/back_huang.png") +
          ") no-repeat center bottom;background-size: contain;"
        );
      } else if (val == "2") {
        // return 'border-bottom-color:#d32ce6;background-image: linear-gradient(to top, rgba(211, 44, 230,.5), transparent)'
        return (
          "background: url(" +
          require("@/assets/images/Roll/back_fen.png") +
          ") no-repeat center bottom;background-size: contain;"
        );
      } else if (val == "3") {
        // return 'border-bottom-color:#834fff;background-image: linear-gradient(to top, rgba(131, 79, 255,.5), transparent)'
        return (
          "background: url(" +
          require("@/assets/images/Roll/back_lan.png") +
          ") no-repeat center bottom;background-size: contain;"
        );
      } else if (val == "4") {
        // return 'border-bottom-color:#4b69ff;background-image: linear-gradient(to top, rgba(75, 105, 255,.5), transparent)'
        return (
          "background: url(" +
          require("@/assets/images/Roll/back_hui.png") +
          ") no-repeat center bottom;background-size: contain;"
        );
      } else {
        // return 'border-bottom-color:#bbbbbb;background-image: linear-gradient(to top, rgba(187, 187, 187,.5), transparent)'
        return (
          "background: url(" +
          require("@/assets/images/Roll/back_hui.png") +
          ") no-repeat center bottom;background-size: contain;"
        );
      }
    },
  },
  mounted() {
    this.tabIndex = parseInt(localStorage.getItem('tabIndex') );
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
 
    tabChange(index){
      console.log(index)
      // this.SetTabIndex(index);
      localStorage.setItem('tabIndex',index);
     
      this.tabIndex = parseInt(localStorage.getItem('tabIndex') );
      console.log(index,this.tabIndex,index === this.tabIndex,'this.tabIndex');
    },
    handleScroll() {
      this.isScrolling = true;
      this.sidebarTransform = '-0.25rem';
      clearTimeout(this.scrollTimer);
      this.scrollTimer = setTimeout(() => {
        this.isScrolling = false;
        this.sidebarTransform = '';
      }, 500); // 设置一个延时，判断用户停止滚动
    },
    getBannerList() {
      Info().then((res) => {
        // console.log(res.data.data.banners, 'banners');
        this.swiperList = res.data.data.banners;
        this.swiperList = this.swiperList.map(item => {
          return {
            ...item,
            href: item?.href?.replace('tmskins', 'm.tmskins')
          };
        });
        // this.swiperList = [
        //   {
        //     href: "https://m.tmskins.com",
        //     image: require("../../assets/images/mobile/m-banner-0.png"),
        //     title: "banner0"
        //   },
        //   {
        //     href: "https://m.tmskins.com/Roll",
        //     image: require("../../assets/images/mobile/m-banner-1.png"),
        //     title: "banner1"
        //   },
        //   {
        //     href: "https://m.tmskins.com/shareReward",
        //     image: require("../../assets/images/mobile/m-banner-2.png"),
        //     title: "banner2"
        //   },
        //   {
        //     href: "https://m.tmskins.com/shareReward",
        //     image: require("../../assets/images/mobile/m-banner-3.png"),
        //     title: "banner3"
        //   },
        // ];
        // this.swiperList = [
        //   { image: 'https://api.acekeys.net/uploads/20240129/04908e4e89fda46c6ce2b775f4a2ef5e.png' },
        //   { image: 'https://api.acekeys.net/uploads/20240129/1ebfbd25566a06476026da19d9ef3abb.png' },
        //   { image: 'https://api.acekeys.net/uploads/20240129/4347103b1d1fdcffb249eb3992aa9e13.png' }
        // ];
      });
    },
    // 写死跳转roll房
    goroll(item) {
      if (!this.user.id) {
        this.$message.error("请先登录");
        return false;
      }
      location.href = item.href;
      // this.$router.push('/Roll');
    },
    gotoActiveChange(item) {
      this.$router.push(item.page);
    },
    playActiveChange(index) {
      this.playingActive = index;
    },
    onChange(index) {
      this.swiperIndex = index;
    },
    onChange1(index) {
      this.$refs.swiper.swipeTo(index);
    },
    //热门宝箱
    ObtainHotBox() {
      GetHotBox().then((res) => {
        this.HotList = res.data.data;
      });
    },
    //开箱
    ToOpen(id) {
      this.$router.push({
        path: "/OpeningBox",
        query: {
          id: id,
        },
      });
    },

 
    ...mapMutations([
      "User",
      "GetIndividualsShow",
      "GetRegisterShow",
      "GetSignInShow",
    ]),

 },
  computed: {
    ...mapState(["user", "IndividualsShow", "RegisterShow", "SignInShow"]),
  },
};
</script>
<style lang="scss" scoped>
.view-blind-box {
  padding: 0.15rem 0.18rem 0.15rem;
  .box-classify {
    display: flex;
    width: 3.35rem;
    height: 0.35rem;
    .blind-List-box {
       display: flex;
        flex-wrap: nowrap;
        overflow-x: auto;
        // margin-top: 0.08rem;
        .list-tile-box {
            // width: 1.12rem;
            // height: 0.4rem;
            width: 0.70rem;
            background: url(../../assets/newImages/classify-bg.png) no-repeat;
            background-size: 100% 100%;
            background-position: 0 0;
            margin-right: 0.16rem;
            display: flex;
            justify-content: center;
            align-items: center;
          
            .classify-title {
              width: 1.4rem;
              font-size: 0.14rem;
              text-align: center;
              overflow: hidden;
                
              img {
                width: 100%;
                height: 100%;
              }
          }
        }
        .list-tile-box-active{
            background-color: #33211A;
            background-image: url(../../assets/newImages/classify-bg-active.png) ;
            background-size: 100% 100%;
        }
   

    }
      
  }
  .play-introduce {
      width: 0.72rem;
      height: 0.16rem;
      line-height: 0.16rem;
      display: flex;
      justify-content: center;
      align-content: center;
      padding: 0.12rem 0;
        font-size: 0.12rem;
        color: #FFEDD1;
        img {
          width: 0.16rem;
          height: 0.16rem;
          margin-right: 0.02rem;
        }
   }

  .custom-indicator {
    position: absolute;
    bottom: 0.2rem;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    cursor: pointer;

    i {
      display: block;
      width: 0.25rem;
      height: 0.25rem;
      border-radius: 50%;
      background: #575e60;
      margin-right: 0.1rem;
    }

    .active-swiper {
      background: #d87b28;
    }
  }

  .playing-active {
    width: 100%;

    .playing-wrap {
      // width: 3.4rem;
      padding-top: 0.3rem;
      display: flex;
      flex-direction: column;
      align-items: center;

      .playing-box {
        display: flex;

        .play-item {
          position: relative;
          cursor: pointer;

          // .playing-item-img {
          //   width: 0.8rem;
          //   // height:1rem;
          // }
          &:nth-child(1) {
            .playing-item-img {
              width: 1rem;
            }
          }

          &:nth-child(2) {
            .playing-item-img {
              width: 1rem;
            }
          }

          &:nth-child(3) {
            .playing-item-img {
              width: 1rem;
            }
          }

          &:nth-child(4) {
            .playing-item-img {
              width: 1rem;
            }
          }

          .play-info {
            position: absolute;
            bottom: 35%;
            left: 50%;
            display: flex;
            flex-direction: column;
            align-items: center;
            transform: translateX(-35%);

            span {
              font-size: 0.08rem;
            }

            img {
              height: 0.12rem;
              max-width: 100px;
            }
          }

          .play-info:nth-of-type(4) {
            // margin-top: 10px;
          }

          &:nth-child(1) {
            transform: translate(18%, 4%);

            .play-info {
              transform: translate(-30%, -101%);
            }
          }

          &:nth-child(2) {
            transform: translate(13%, 0%);

            .play-info {
              transform: translate(-60%, 10%);
            }
          }

          &:nth-child(3) {
            transform: translate(-5%, 0%);

            .play-info {
              transform: translate(-60%, 40%);
            }
          }

          &:nth-child(4) {
            .play-info {
              transform: translate(-85%, -278%);
            }

            transform: translate(-14%, 2%);
          }
        }
      }
    }
  }

  .home-box {
    width: 100%;
    .home-List-box {
      
      .Title {
        width: 100%;

        .play-title {
          // width: 3.4rem;
          height: 0.35rem;
          margin: 0 auto;
        }
      }

      .home-List {
        margin-top: 0.14rem;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        
        .home-list-item {
                width: 1.06rem;
                box-sizing: border-box;
                cursor: pointer;
                position: relative;
                display: flex;
                justify-content: center;
                flex-wrap: wrap;
                margin: 0 0.03rem;
                // padding: 0.06rem 0.13rem;
                margin-bottom: 0.07rem;
                background-color: #1d1300;
                border: 0.01rem solid #69552D;

          .left-icon {
            position: absolute;
            top: 0rem;
            left: 0rem;
          }

          .wrap_pic {
            height: 1.5rem;
            height: 0.91rem;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;

            img {
              height: 100%;
            }

            .weapon_cover {
              position: absolute;
              height: 0.6rem;
              display: flex;
              align-items: center;
              justify-content: center;

              img {
                height: 100%;
              }
            }
          }

          .Blind_btn {
            // text-align: center;
            // margin-bottom: 0.08rem;
            // color: #cecece;
            // font-size: 0.12rem;
            // margin-top: -0.1rem;
                width: 100%;
                box-sizing: border-box;
                text-align: center;
                font-size: 0.1rem;
                // margin: 0.02rem 0;
          }
          .box-price {
            margin: 0.04rem 0;
          }
        }
      }
    }
  }

  @keyframes zhuan {
    to {
      transform: rotate(1turn);
    }
  }
}
</style>
